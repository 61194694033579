/* Vendor imports */
import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
/* App imports */
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import PostList from "../../components/post-list";
import Config from "../../../config";

const IndexPage = ({ data }) => (
  <Layout logo={data.bclLogoIcon} siteDescription={Config.siteCasualDescr}>
    <SEO title="Casual" description={Config.siteCasualDescr} path="casual" />
    <PostList posts={data.allMarkdownRemark.edges} />
  </Layout>
);

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  {
    bclLogoIcon: file(name: { eq: "bcl-logo-casual" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 48, height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/casual/.*/index.md$/" } }
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            tags
            links
            excerpt
            cover {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
